import React from 'react';
import Error from 'next/error';
import Redirect from '../components/Redirect';
import ClearSiteDataButton from '../ClearSiteDataButton';

const ServerErrorPage = () => (
  <>
    <ClearSiteDataButton positionAbsolute />
    <Error statusCode={500} />
    <Redirect />
  </>
);

export default ServerErrorPage;
